"use client"
import React from "react";
import { MenuProps } from "..";
import { ThemeContext } from "styled-components";
import { UIContext } from "@/context";
import { translationNamespace, useLocales } from "@/hooks";
import { Flex, Icon, Modal } from "@ui-components";
import { SearchButtonStyled } from "../styled";
import { SearchModalContent } from "../../SearchModalContent";

const SearchLink: React.FC<MenuProps> = (props) => {
    const ref = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
    const theme = React.useContext(ThemeContext);
    const { toggleMegaMenu, toggleNavFoldout } = React.useContext(UIContext);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const MODAL_ID = "search-modal";
    const locales = useLocales(translationNamespace.common("Header"), [
      "openSearch",
    ]);
  
    return (
      <>
        {props?.menu?.data?.main &&
          props?.menu?.data.main.map((item, i) =>
            item?.linkType === "SEARCH_PAGE" &&
            typeof theme?.dark === "boolean" ? (
              <React.Fragment key={i + "-" + item?.title.replace(/\s+/g, '')}>
                {/* Search link desktop */}
                <Flex
                  display={{ _: "none", xl: "flex" }}
                  mr={2.5}
                  alignItems="center"
                >
                  <SearchButtonStyled
                    ref={ref}
                    textColor={theme.dark ? "white" : "black"}
                    onClick={() => {
                      setIsModalOpen(true);
                      toggleMegaMenu(false);
                    }}
                    type="button"
                    aria-label={locales.openSearch}
                    aria-controls={MODAL_ID}
                    aria-expanded={isModalOpen ? "true" : "false"}
                  >
                    <Flex>
                      {item.title}
                      <Icon
                        icon="search"
                        size={2}
                        ml={1}
                        mt={-0.125}
                        color={theme.dark ? "white" : "black"}
                      />
                    </Flex>
                  </SearchButtonStyled>
                </Flex>
  
                {/* Search link mobile */}
                <Flex display={{ _: "flex", xl: "none" }} mr={2}>
                  <SearchButtonStyled
                    textColor={theme.dark ? "white" : "black"}
                    onClick={() => {
                      setIsModalOpen(true);
                      toggleNavFoldout(false);
                    }}
                    type="button"
                    aria-label={locales.openSearch}
                  >
                    <Icon
                      icon="search"
                      size={2.5}
                      ml={{ _: 0, l: 1 }}
                      mt={{ _: 0.125, l: -0.125 }}
                      color={theme.dark ? "white" : "black"}
                    />
                  </SearchButtonStyled>
                </Flex>
  
                {/* Portal modal */}
                <Modal
                  modalId={MODAL_ID}
                  isOpen={isModalOpen}
                  handleClose={() => setIsModalOpen(false)}
                  lastFocusedElement={ref.current}
                >
                  <SearchModalContent handleClose={() => setIsModalOpen(false)} />
                </Modal>
              </React.Fragment>
            ) : null
          )}
      </>
    );
  };

  export { SearchLink };