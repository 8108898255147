import { sendMatomoEvent } from "../../utils";
import { Megamenu as IMegaMenu } from "../../@types/graphql.generated";

/**
 *   Push Motomo event for clicks on mega menu links
 */
export const trackMegaMenuClickEvent = (
  linkTitle: string,
  linkTarget: string
) => {
  sendMatomoEvent({
    event: "Main Navigation More Menu",
    analyticsinfo: linkTitle,
    clickTarget: linkTarget,
  });
};

/**
 *   Push Motomo event for clicks on main menu links
 */
export const trackMainMenuClickEvent = (linkTitle: string) => {
  sendMatomoEvent({
    event: "Main Navigation",
    analyticsinfo: linkTitle,
  });
};

/**
 *   Push Motomo event for clicks on main menu links
 */
export type ButtonLocation =
  | "Main menu"
  | "Show page hero"
  | "Header"
  | "Programme";
export const trackTicketLinkClickEvent = (
  buttonLabel: string,
  location: string,
  buttonLocation: ButtonLocation
) => {
  sendMatomoEvent({
    event: "Ticket Button",
    location: buttonLocation,
    analyticsinfo: buttonLabel,
    clickTarget: location,
  });
};

export const distributeMenuItemsEvenly = (
  menu: IMegaMenu[],
  columns: number,
  allowedColumnSize?: number
): IMegaMenu[][] => {
  const totalItems = menu.length + menu.reduce((acc, item) => acc + ((item?.children?.length ?? 0) + 1), 0);
  const initialColumnSize = allowedColumnSize ?? Math.ceil(totalItems / columns);

  const columnItems: IMegaMenu[][] = Array.from({ length: columns }, () => []);
  const columnCounts = Array(columns).fill(0); // Track item count in each column
  let currentColumn = 0;

  // Step 1: Attempt to distribute items into columns
  for (const item of menu) {
    const itemCount = (item.children?.length ?? 0) + 1;

    if (columnCounts[currentColumn] + itemCount > initialColumnSize) {
      currentColumn++;
      if (currentColumn >= columns) {
        // If we exceed the number of columns, retry with a larger column size
        return distributeMenuItemsEvenly([...menu], columns, initialColumnSize + 1);
      }
    }

    columnItems[currentColumn].push(item);
    columnCounts[currentColumn] += itemCount;
  }

  // Step 2: Redistribute to fill columns
/*
  const filledColumns = columnItems.filter(col => col.length > 0); // Remove any completely empty columns
  const remainingColumns = columns - filledColumns.length;

  if (remainingColumns > 0) {
    // Redistribute items to ensure all columns are used
    const flattened = filledColumns.flat(); // Flatten items into a single list
    const balancedColumns: IMegaMenu[][] = Array.from({ length: columns }, () => []);
    let colIndex = 0;

    for (const item of flattened) {
      balancedColumns[colIndex].push(item);
      colIndex = (colIndex + 1) % columns; // Distribute sequentially across all columns
    }

    return balancedColumns;
  }
*/
  return columnItems;
};