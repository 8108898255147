"use client";
import { UIContext } from "@/context";
import { MenuProps } from "./Menu";
import { usePathname } from "next/navigation";
import { translationNamespace, useLocales } from "@/hooks";
import { Accordion, colors, CustomLink, CustomNoLink, Flex, Submenu } from "@ui-components";
import { Megamenu as IMegaMenu } from "../../@types/graphql.generated";
import React from "react";

// Mobile foldout submenu
const mapFoldoutSubmenuItems = (
  menu: IMegaMenu[],
  path: string,
  toggleNavFoldout: (state: boolean) => void,
  locales: Record<string, string>
) =>
  menu.map((item) => {
    return item.children && item.children.length > 0 ? (
      <Accordion key={`accordion-${item.id}`}>
        <Accordion.Header
          id={`submenu-${item.url}-${item.id}`}
          toggleLabel={
            locales?.showMoreLinksFor
              ? locales.showMoreLinksFor.replace(/%s/g, item.title)
              : ""
          }
          noBorder={true}
        >
          {/* 1st Level Title */}
          <Flex pt={0.5}>
            {item.url ? (
              <CustomLink
                href={item.url}
                onClick={() => toggleNavFoldout(false)}
                label={item.title.toUpperCase()}
                withChevron={false}
                size={3}
              />
            ) : (
              <CustomNoLink
                label={item.title.toUpperCase()}
                withChevron={false}
                size={3}
              />
            )}
          </Flex>
        </Accordion.Header>
        <Accordion.Panel
          id={`submenu-${item.url}-${item.id}`}
          key={`accordion-panel-${item.id}`}
          lessPadding
        >
          {item.children?.map((link, idx) => {
            return (
              <Flex key={link.id} flexDirection={"column"}>
                {link.children && link.children.length > 0 ? (
                  <Accordion>
                    <Accordion.Header
                      id={`submenu-${link.url}-${link.id}`}
                      toggleLabel={
                        locales?.showMoreLinksFor
                          ? locales.showMoreLinksFor.replace(/%s/g, link.title)
                          : ""
                      }
                      noBorder={true}
                    >
                      <Flex pl={{ _: 2, s: 4 }}>
                        {/* 2nd Level Title */}
                        {link?.url ? (
                          <CustomLink
                            href={link.url}
                            onClick={() => toggleNavFoldout(false)}
                            label={link.title ?? ""}
                            withChevron={false}
                            size={2.25}
                          />
                        ) : (
                          <CustomNoLink
                            label={link?.title ?? ""}
                            withChevron={false}
                            size={2.25}
                          />
                        )}
                      </Flex>
                    </Accordion.Header>
                    <Accordion.Panel id={`submenu-${link.url}-${link.id}`} lessPadding>
                      <Flex pl={{ _: 4.75, s: 6.75, m: 9.75 }} pr={0}>
                        <Submenu>
                          <Submenu.List>
                            {link?.children?.map((sublink, idx) => (
                              <Submenu.Item
                                active={path === sublink.url}
                                key={idx}
                              >
                                <Submenu.Link
                                  href={sublink.url ? sublink.url : "#"}
                                  onClick={() => toggleNavFoldout(false)}
                                >
                                  {/* 3rd Level Title */}
                                  <Flex py={2}>{sublink?.title}</Flex>
                                </Submenu.Link>
                              </Submenu.Item>
                            ))}
                          </Submenu.List>
                        </Submenu>
                      </Flex>
                    </Accordion.Panel>
                  </Accordion>
                ) : (
                  <Flex w={1} py={2.5} pl={{ _: 2, s: 4, m: 6.5 }}>
                    {/* 2nd Level Title */}
                    <CustomLink
                      href={link.url ? link.url : "#"}
                      onClick={() => toggleNavFoldout(false)}
                      label={link.title ?? ""}
                      withChevron={false}
                      size={2.25}
                    />
                  </Flex>
                )}
              </Flex>
            );
          })}
        </Accordion.Panel>
      </Accordion>
    ) : (
      <Flex pl={{_: 0, m: 2.5}} py={2.5} key={`link-${item.id}`}>
        <CustomLink
          href={item.url ? item.url : "#"}
          onClick={() => toggleNavFoldout(false)}
          label={item.title.toUpperCase()}
          withChevron={false}
          size={3}
        />
      </Flex>
    );
  });

const FoldoutSubMenu: React.FC<MenuProps> = React.memo((props) => {
  const pathname = usePathname();
  const { toggleNavFoldout } = React.useContext(UIContext);
  const locales = useLocales(translationNamespace.common("Header"), [
    "showMoreLinksFor",
  ]);

  return (
    <Flex
      flexDirection="column"
      mt={0}
      pt={2}
      pb={25}
      px={2}
      style={{
        backgroundColor: colors["greybrown"],
        opacity: props?.menu?.loading ? 0 : 1,
      }}
    >
      <nav>
        {props?.menu?.data?.megamenu &&
          mapFoldoutSubmenuItems(
            props.menu.data.megamenu as IMegaMenu[],
            pathname,
            toggleNavFoldout,
            locales
          )}
      </nav>
    </Flex>
  );
});
FoldoutSubMenu.displayName = "FoldoutSubMenu";

export { FoldoutSubMenu };
