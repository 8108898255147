"use client";
import React, { useEffect } from "react";
import Link from "next/link";
import { ThemeProvider } from "styled-components";

// components
import {
  Menu,
  MegaMenu,
  FoldoutSubMenu,
  CalendarButton,
  SearchLink,
  MyProfile,
} from "../Menu";
import {
  Header as UIHeader,
  Flex,
  NavToggle,
  Foldout,
  HeaderLogo,
  lightTheme,
  SkipLink,
  Text,
  scale,
} from "@ui-components";
import { LanguageSwitch } from "../LanguageSwitch";
import { UIContext } from "../../context/UIContext";
import { useLocales, translationNamespace } from "../../hooks/useLocales";
import { GetMenuQueryResult } from "../Menu/operations.generated";

const Header = ({ menu }: { menu: GetMenuQueryResult }) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const skipLinkRef = React.useRef<HTMLAnchorElement>(null);
  const [skipLinkHasFocus, setSkipLinkHasFocus] =
    React.useState<boolean>(false);
  const { toggleNavFoldout, navFoldoutOpen } = React.useContext(UIContext);
  const [menuHeight, setMenuHeight] = React.useState<number>(0);

  const locales = useLocales(translationNamespace.common("Header"), [
    "goLogoUrl",
    "operaLogoLinkLabel",
    "openMegaMenuLabel",
    "menu",
  ]);

  const skipLinkLocales = useLocales(translationNamespace.common("SkipLink"), [
    "skipLinkLabel",
  ]);

  useEffect(() => {
    const handleResize = () => {
      setMenuHeight(
        (headerRef.current?.clientHeight || 0) +
          (skipLinkHasFocus
            ? skipLinkRef.current?.clientHeight || 0
            : 0)
      );
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [headerRef, skipLinkRef, skipLinkHasFocus]);

  return menu?.data?.megamenu ? (
    <>
      <SkipLink
        label={skipLinkLocales.skipLinkLabel}
        ref={skipLinkRef}
        handleFocus={() => setSkipLinkHasFocus(true)}
        handleBlur={() => setSkipLinkHasFocus(false)}
      />

      <UIHeader fixed={navFoldoutOpen} ref={headerRef}>
        <Flex h="100%" alignItems="start">
          <Flex mt={1.25} mb={1.25}>
            <Link
              href={locales.goLogoUrl}
              title={locales.operaLogoLinkLabel}
              onClick={() => toggleNavFoldout(false)}
            >
              <HeaderLogo />
            </Link>
          </Flex>
          <Flex
            alignItems={{ _: "center", xl: "flex-start" }}
            ml="auto"
            h="100%"
          >
            <>
              {/* Desktop main menu */}
              <Flex
                display={{ _: "none", xl: "flex" }}
                ml={scale.px(2)}
                mt={{ _: 0.25, xl: 0.5 }}
                h="100%"
              >
                <Menu menu={menu} />
              </Flex>
              <Flex h={9} alignItems="center">
                <Flex
                  display={{ _: "none", xl: "flex" }}
                  mr={{ _: 1, xl: 2.5 }}
                  alignItems="center"
                >
                  <Text
                    fontWeight="bold"
                    fontSize={2}
                    mb={0}
                    mr={{ xl: 2.5, xxl: 4 }}
                    tabIndex={-1}
                  >
                    |
                  </Text>
                  <LanguageSwitch useShortTitle />
                </Flex>
                <Flex width="max-content">
                  <MyProfile menu={menu} />
                  <SearchLink menu={menu} />
                </Flex>
              </Flex>
              <CalendarButton menu={menu} />
              {/* Navtoggle for mobile view */}
              <Flex display={{ _: "block", xl: "none" }} ml={{ _: 2, xl: 0 }}>
                <NavToggle
                  onClick={toggleNavFoldout}
                  aria-label={locales.openMegaMenuLabel || ""}
                  open={navFoldoutOpen}
                  locales={{
                    label: locales.menu,
                  }}
                />
              </Flex>
            </>
          </Flex>
        </Flex>
      </UIHeader>
      <ThemeProvider theme={lightTheme}>
        <MegaMenu menu={menu} openAt={menuHeight} />

        {/* Mobile foldout menu */}
        <Flex display={{ _: "block", xl: "none" }}>
          <Foldout open={navFoldoutOpen}>
            {/*<Menu menu={menu} />*/}
            <FoldoutSubMenu menu={menu} />
            <Flex
              justifyContent="flex-end"
              alignItems="center"
              mr={3.5}
              mt={-14}
            >
              <LanguageSwitch id="language-switch-header-mobile" useLabel />
            </Flex>
          </Foldout>
        </Flex>
      </ThemeProvider>
    </>
  ) : null;
};

export { Header };
