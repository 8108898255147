import * as React from "react";
import {
	Flex,
	Text,
	READABLE_LINE_MAX_WIDTH,
	scale,
	colors,
	Icon,
	getDesktopAndMobileImageProps,
	desktopSizes,
	mobileSizes,
} from "@ui-components";
import styled from "styled-components";
import { Block } from "../Block/Block";
import { NewsletterBlock as INewsletterBlock } from "../../@types/graphql.generated";
import { imageDataToImageProps } from "../../utils";
import { useNewsletterForm } from "@/hooks/useNewsletterForm";

const RelativeDiv = styled.div`
	position: relative;
`;

const BackgroundImage = styled.img.withConfig({
	shouldForwardProp: (prop, defaultValidatorFn) => 
		!["fetchPriority"].includes(prop),
})`
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
`;

const NotificationError = styled.div`
	background-color: ${colors["pink-light"]};
	color: ${colors["red-dark"]};
	padding: ${scale.px(2)};
	margin-top: ${scale.px(3)};
`;

const NotificationInvalid = styled.div`
	background-color: ${colors["pink-light"]};
	color: ${colors["red-dark"]};
	padding: ${scale.px(2)};
	margin-top: ${scale.px(3)};
`;

const NotificationSuccess = styled.div`
	background-color: ${colors["green-light"]};
	color: ${colors["green"]};
	padding: ${scale.px(2)};
	margin-top: ${scale.px(3)};
`;

const NewsletterBlock: React.FC<INewsletterBlock> = props => {
	const divRef = React.useRef<HTMLDivElement>(null);
	const newsletterStatus = useNewsletterForm(divRef, props?.buttonText ?? "", props?.privacyPolicyInformation ?? "", props?.placeholderText ?? "");

	const imageProps = props?.backgroundImage ? imageDataToImageProps(props.backgroundImage) : null;
	const nextImageProps = imageProps ? getDesktopAndMobileImageProps(imageProps) : null;

	return (
		<Block themeColorVariant={props.themeColorVariant ?? undefined} theme={props.theme ?? undefined}>
			<RelativeDiv>
				{nextImageProps && (
					<picture>
						<source media="(min-width: 751px)" srcSet={nextImageProps.desktop} sizes={desktopSizes} />
						<source media="(max-width: 750px)" srcSet={nextImageProps.mobile} sizes={mobileSizes} />
						<BackgroundImage {...nextImageProps.rest} />
					</picture>
				)}
			<Flex
				display="block"
				maxw={READABLE_LINE_MAX_WIDTH}
				w={1}
				py={{ _: 8, l: 8 }}
				style={{ margin: "0 auto", position: "relative" }}
				pl={{ _: 2, m: 5, l: 11 }}
				pr={{ _: 2, m: 5, l: 11 }}
			>
				<Flex
					backgroundColor={"black"}
					flexDirection="column"
					alignItems="center"
					textColor={"white"}
					px={6}
					py={8}
				>
					{props.title && (
						<Text
							variant="headline-2"
							mb={{ _: 1, m: 1 }}
							pt={0}
							textAlign="center"
							textColor={"white"}
						>
							{props.title}
						</Text>
					)}
					{props.text && (
						<Text
							variant="paragraph-1"
							as="p"
							textAlign="center"
							textColor={"white"}
						>
							{props.text}
						</Text>
					)}
					<div ref={divRef}></div>
					{ newsletterStatus === 'invalid' &&  
						<NotificationInvalid>
							<Flex
								flexDirection="row"
								alignItems="center"
								justifyContent="fle x-start"
								style={{gap: "1rem"}}
							>
								<Icon 
									icon="alert"
									color={"red"}
									size={3}
								/>
								{props?.invalidInput}
							</Flex>
						</NotificationInvalid> 
					}
					{ newsletterStatus === 'error' &&  
						<NotificationError>
							<Flex
								flexDirection="row"
								alignItems="center"
								justifyContent="fle x-start"
								style={{gap: "1rem"}}
							>
								<Icon 
									icon="alert"
									color={"red"}
									size={3}
								/>
								{props?.failMessage}
							</Flex>
						</NotificationError> 
					}
					{ newsletterStatus === 'success' && 
						<NotificationSuccess>
							<Flex
								flexDirection="row" 
								alignItems="center"
								justifyContent="flex-start"
								style={{gap: "1rem"}}
							>
								<Icon 
									icon="check"
									color={"green"}
									size={3}
								/>
								{props?.successMessage}
							</Flex>
						</NotificationSuccess>
					}
				</Flex>
			</Flex>
			</RelativeDiv>
		</Block>
	)
}

export { NewsletterBlock }
