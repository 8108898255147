'use client'
import * as React from "react"
import { Megamenu as IMegaMenu } from "../@types/graphql.generated";

type UIContextType = { 
	navFoldoutOpen: boolean
	toggleNavFoldout: (open?: boolean) => void
	megaMenuOpen: boolean
	toggleMegaMenu: (open?: boolean) => void
	currentOpenMegaMenu: string | undefined
	setCurrentMegaMenu: (id?: string) => void
}

export const UIContext = React.createContext<UIContextType>({
	navFoldoutOpen: false,
	toggleNavFoldout: (open?: boolean) => {},
	megaMenuOpen: false,
	toggleMegaMenu: (open?: boolean) => {},
	currentOpenMegaMenu: undefined,
	setCurrentMegaMenu: (id?: string) => {}
});

export const UIContextProvider: React.FC<React.PropsWithChildren> = props => {
	const [navFoldoutOpen, setNavFoldoutOpen] = React.useState(false);
	const [megaMenuOpen, setMegaMenuOpen] = React.useState(false);
	const [currentOpenMegaMenu, setCurrentOpenMegaMenu] = React.useState<string|undefined>();

	const toggleNavFoldout = (open: boolean | undefined) => {
		if (open === false) {
			setNavFoldoutOpen(false)
		} else {
			setNavFoldoutOpen(!navFoldoutOpen)
		}
	}

	const toggleMegaMenu = (open: boolean | undefined) => {
		if (open === false) {
			setMegaMenuOpen(false)
		} else {
			setMegaMenuOpen(!megaMenuOpen)
		}
	}

	const setCurrentMegaMenu = (id: string | undefined) => {
		setCurrentOpenMegaMenu(id);
	}

	React.useEffect(() => {
		const handleKeyUp = (e: KeyboardEvent) => {
			if (e.key === "Escape") {
				e.preventDefault()
				toggleMegaMenu(false)
			}
		}

		window.addEventListener("keyup", handleKeyUp)
		return () => window.removeEventListener("keyup", handleKeyUp)
	})

	return (
		<UIContext.Provider
			value={{
				navFoldoutOpen,
				toggleNavFoldout,
				megaMenuOpen,
				toggleMegaMenu,
				currentOpenMegaMenu,
				setCurrentMegaMenu
			}}
			{...props}
		/>
	)
}
